<template>
  <div id="cms-user-edit">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">User - Edit</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Username:"
                    label-for="username"
            >
              <b-form-input id="username" v-model="user.username" style="color: darkgrey" disabled></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Name:"
                    label-for="name"
            >
              <b-form-input id="name" v-model="user.name"></b-form-input>
            </b-form-group>
            <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="E-mail:"
                    label-for="email"
            >
              <b-form-input id="email" v-model="user.email"></b-form-input>
            </b-form-group>
            <router-link class="link-color" to="/cms/users" @click.prevent>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </router-link>
            <b-button  @click.prevent="saveUser()" type="submit" variant="success">Submit</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        loading: true,
        user: []
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get(`https://batavia-backend.herokuapp.com/api/user/${this.$route.params.id}`)
          .then(response => (
              this.user = response.data
          ))
          .finally(() => {
            this.loading = false
          })
    },
    methods: {
      saveUser() {
        this.axios
            .patch(
                `https://batavia-backend.herokuapp.com/api/user/${this.$route.params.id}`,
                {
                  name: this.user.name,
                  email: this.user.email,
                },
                {
                  headers: {
                    Authorization: axios.defaults.headers.common.Authorization
                  }
                }
            )
            .then(() => {
              this.$router.push({ name: 'CmsUsers' })
            })
      }
    }
  }
</script>